// check permissions here:
// https://kramphub.atlassian.net/wiki/spaces/BOUN/pages/1255899148/Kramp+roles

export const pageAccessControl = {
  '/quotation': [
    'quotation.modify',
    'quotation.admin',
    'quotation.submit',
    'quotation.submit_externally',
    'quotation.organization.admin',
  ],
  '/campaign': ['campaign.view', 'campaign.admin'],
  '/organization': ['bouncer.organization.admin', 'security.impersonate'],
  '/catalog': [],
  '/search/suppress-results': [
    'search.suppressions.admin',
    'search.suppressions.viewer',
  ],
  '/experimentation': ['experimentation.view', 'experimentation.admin'],
  '/search/synonyms': ['search.synonyms.admin', 'search.synonyms.viewer'],
  '/search/landingspages': [
    'search.landingspages.admin',
    'search.landingspages.viewer',
  ],
  '/search': [
    'search.landingspages.admin',
    'search.landingspages.viewer',
    'search.suppressions.admin',
    'search.suppressions.viewer',
    'search.synonyms.admin',
    'search.synonyms.viewer',
  ],
  //TODO: come up with an actual role for alpha users
  '/alpha': [],
  //'/hyperloop': ['hyperloop.viewer', 'hyperloop.user'],
  '/hyperloop': [],
  // TODO: Are permission required with Delivery app
  '/delivery': [],
  '/role': [],
  '/dashboard': [],
  '/data-inspection': ['dc3.datainspection.access'],
};

export const featureConfiguration = {
  impersonation: ['security.impersonate'],
  campaignEdit: ['campaign.admin'],
  roleAssignment: ['bouncer.roles.assigner'],
  quotationEdit: ['quotation.modify', 'quotation.admin'],
  quotationSubmit: [
    'quotation.admin',
    'quotation.submit',
    'quotation.submit_externally',
  ],
  searchSuppressionsEdit: ['search.suppressions.admin'],
  searchSynonymsEdit: ['search.synonyms.admin'],
  searchLandingEdit: ['search.landingspages.admin'],
};
